import React, { Component } from "react";
import "../../App.css";
import Layout from "../../Components/Layout";

export default class ProjectWishlists extends Component {
  render() {
    return (
      <div>
        <Layout>
          <h1>Wishlists</h1>
        </Layout>
      </div>
    );
  }
}
